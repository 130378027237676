import React from 'react'
import { Button, useToast, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import BasePage from '../../components/basePage'

import Switch from '../../components/switch'
import NoteScrollable from '../../components/note-scrollable'
import { useState } from 'react'
import {
	getBookingId,
	getEventId,
	getRedirectUrl,
	getTempProfile,
	removeRedirectUrl,
	removeTempProfile
} from '../../helpers/database'
import { createProfile } from '../../services/user'
import { useMutation } from '@tanstack/react-query'
import { errorToastOptions } from '../../helpers'
import { graphql, useStaticQuery } from 'gatsby'
import { useAuth } from '../../hooks/useAuth'
import eventTracking from '../../services/eventTracking'
import { navigate } from 'gatsby'

const TermsPage = ({
	successPath,
	inVenue,
	fullWidthActionBtns,
	backBtn,
	onClose,
	closeBtnText
}) => {
	const [accepted, setAccepted] = useState(false)
	const [subscribed, setSubscribe] = useState(false)
	const [scrolled, setScrolled] = useState(false)
	const { user, refreshProfile } = useAuth()
	const toast = useToast()
	const isValid = accepted

	const data = useStaticQuery(FetchTearmsQuery)
	const terms = data?.allFile.edges[0].node.childMarkdownRemark.html || ''

	const mutation = useMutation(createProfile, {
		onSuccess: async (data) => {
			await refreshProfile()
			removeTempProfile()

			const bookingId = getBookingId()
			const eventId = getEventId()
			const redirectUrl = getRedirectUrl()

			if (successPath) {
				return navigate(successPath)
			}

			if (!!bookingId) {
				return navigate(`/start?bookingId=${bookingId}`)
			}
			if (!!eventId) {
				return navigate(`/start?eventId=${eventId}`)
			}

			if (
				!!redirectUrl &&
				(redirectUrl.includes('/groups/') ||
					redirectUrl.includes('/event-code'))
			) {
				removeRedirectUrl()
				return navigate(redirectUrl)
			}
			return navigate('/login/account-created')
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const onSubmit = async () => {
		if (!accepted) return
		const tempProfile = getTempProfile()
		const { name, surname, nickname, birth } = tempProfile
		const data = {
			name,
			surname,
			nickname,
			acceptedTerms: accepted,
			receiveNews: subscribed
		}
		if (birth) data.birth = birth
		mutation.mutate(data)

		if (!!scrolled) {
			eventTracking('T&C_Scroll', {
				email: user?.email
			})
		}

		if (!!subscribed) {
			eventTracking('OK_news', {
				email: user?.email
			})
		}
	}

	return (
		<BasePage
			theme="dark"
			access={1}
			parent="login"
			fullWidthActionBtns={fullWidthActionBtns}
			onClose={onClose}
			hideSidebar={inVenue}
			backBtn={backBtn}
			closeBtn={!!onClose}
			closeBtnText={closeBtnText}
			largeTitle={
				inVenue ? (
					<>
						<Text color="white" as="h1" textAlign="center">
							WELCOME TO
						</Text>
						<StaticImage
							width={380}
							alt="Logo"
							style={{ marginBottom: 80 }}
							src="../../images/logo-white.png"
						/>
						<br />
						Terms +
						<br />
						Conditions
					</>
				) : (
					<>
						Terms +
						<br />
						Conditions
					</>
				)
			}
			primaryBtn={
				<Button
					variant="primary"
					isLoading={mutation.isLoading}
					disabled={!isValid || mutation.isLoading}
					onClick={onSubmit}
				>
					Continue
				</Button>
			}
		>
			<NoteScrollable
				scrollFunction={setScrolled}
				scrolled={scrolled}
				html={terms}
			/>
			<Switch
				onSelected={(val) => setSubscribe(val)}
				text="Send me news, events & offers"
				stretch
			/>
			<Switch
				stretch
				onSelected={(val) => setAccepted(val)}
				text="T’s and C’s fine with me"
				required={true}
			/>
		</BasePage>
	)
}

export default TermsPage

const FetchTearmsQuery = graphql`
	query {
		allFile(filter: { relativePath: { eq: "content/articles/terms.md" } }) {
			edges {
				node {
					childMarkdownRemark {
						html
					}
				}
			}
		}
	}
`
