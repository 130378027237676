import * as React from 'react'
import { Box, Container, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'

const NoteScrollable = (props) => {
	const { text, html, scrollFunction, scrolled } = props
	return (
		<Container variant="note">
			<Container
				variant="noteScrollable"
				zIndex="3"
				sx={{
					listStylePosition: 'inside'
				}}
				onScroll={() => !scrolled && scrollFunction(true)}
			>
				<Text variant="m" color="white">
					{html ? parse(html) : text}
				</Text>
			</Container>
			<Box
				position="absolute"
				top="0"
				bottom="0"
				right="0"
				left="0"
				zIndex="1"
			>
				<StaticImage
					alt="Target"
					style={{ width: '100%', height: '100%' }}
					src="../images/note-black.png"
				/>
			</Box>
		</Container>
	)
}

export default NoteScrollable
