import * as React from 'react'
import { FormControl, FormLabel, Switch } from '@chakra-ui/react'

const Selector = (props) => {
	const { text, required, onSelected, stretch } = props

	return (
		<FormControl
			display="flex"
			alignItems="center"
			justifyContent={'center'}
			mb="15px"
		>
			<FormLabel
				as="h5"
				ml="0"
				mr="25px"
				mb="0"
				width={stretch ? '100%' : 'auto'}
			>
				{text}
			</FormLabel>
			<Switch
				size="lg"
				colorScheme="yellow"
				isRequired={required || false}
				onChange={(e) => {
					onSelected(e?.target?.checked || false)
				}}
			/>
		</FormControl>
	)
}

export default Selector
